@import 'styles/variables';

.buttonContainer {
  background-color: $gray-color;
  border-radius: 0 0 6px 6px;

  @media screen and (max-width: 768px) {
    padding: 12px;
  }
}

.button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-grow: 1;
  padding: 12px;

  color: $white;
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    padding: 12px;
    border-radius: 6px;
    background-color: rgba($white, .05);
  }
}

.buttonIcoUp {
  transform: rotate(180deg);
}