@import 'styles/variables';

.contentWrapper {
    display: grid;
    grid-template-columns: 1fr 428px;
    grid-gap: 12px 16px;

    &.isInSidebar {
        grid-template-columns: 1fr;

        .tableTeams {
            margin-top: 0;
        }
    }
}

/* Playoff table */

.table {
    display: flex;
    flex-flow: column;
    gap: 1px;
}

.tableTeams {
    grid-column: 1;
}

// Table header

.tableHeader {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr repeat(6, 23px) 110px;
    grid-column-gap: 16px;

    min-height: 52px;
    padding: 8px 12px;

    background-color: $gray-color;
    border-radius: 6px 6px 0 0;

    // Hide column for small screens
    & > :nth-child(6) {
        display: none;
    }
}

.tableTitleMobile {
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 50px;
    padding: 8px 12px;

    background-color: $gray-color;
    border-radius: 6px 6px 0 0;
}

.tableTitle {
    font-size: 15px;
    line-height: 18px;
    color: $white;
    font-weight: bold;
    text-transform: uppercase;
}

.tableHeaderLabel {
    color: $white;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    opacity: .6;
}
.labelWithSlash+.labelWithSlash:before {
    content: ' / ';
    opacity: .4;
}

// Table row

.tableRow {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr repeat(6, 23px) 110px;
    grid-column-gap: 16px;

    padding: 10px 12px;

    font-size: 13px;
    color: $white;
    border-radius: 6px;

    &:nth-child(even) {
        background-color: $gray-color;
    }

    // Hide column for small screens
    & > :nth-child(6) {
        display: none;
    }
}

// Duplicated styles!

.teamContainer {
    display: grid;
    align-items: center;
    grid-template-columns: max-content max-content minmax(0, 1fr);
    grid-column-gap: 8px;
}

.teamPosition {
    position: relative;
    height: 26px;
    width: 26px;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 13px;
    font-weight: 700;

    border-radius: 50px;
    background: $gray-third;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
}

.matchesResults {
    display: flex;
    justify-content: flex-end;

    & > *:not(:first-child) {
        margin-left: -4px;
    }
}

.matchLogo {
    width: 20px;
    height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    font-size: 10px;
    color: $text-black;

    border-radius: 50%;
    border: 1px solid $gray-color;
    background-color: $gray-light-second;

    &.colorWin {
        color: $white;
        background-color: $green;
    }

    &.colorLose {
        color: $white;
        background-color: $red-secondary;
    }

    &.nextMatch {
        background-color: $white;
    }
}

/* Matches table */

.tableHeaderMatches {
    display: grid;
    grid-template-columns: repeat(3, 50px);
    align-items: center;
    justify-content: flex-end;
    grid-column-gap: 16px;

    min-height: 52px;
    padding: 8px 12px;

    background-color: $gray-color;
    border-radius: 6px 6px 0 0;
}

.tableRowMatches {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 182px;
    grid-column-gap: 12px;
    padding: 6px 12px;

    font-size: 13px;
    color: $white;
    background-color: $gray-color;

    transition: background-color .2s ease-in;
    &:hover {
        background-color: $bg-sixth;
    }

    &:last-child {
        border-radius: 0 0 6px 6px;
    }
}

/* Adaptive */

.isOnlyDesktop {
    @media screen and (max-width: 540px) {
        display: none;
    }
}
.isOnlyMobile {
    @media screen and (min-width: 541px) {
        display: none;
    }
}

.contentWrapper {
    @media screen and (max-width: 1300px) {
        grid-template-columns: 1fr;
        .tableTeams {
            margin-top: 32px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    @media screen and (max-width: 768px) {
        grid-gap: 8px;

        // Change cols to show in table

        .tableRow {
            padding: 10px 12px;
        }

        // Other table parts

        .tableTitle {
            font-size: 13px;
            line-height: 16px;
        }

        /* Matches */

        .tableHeaderMatches {
            padding: 8px;
            min-height: auto;
        }
    }

    @media screen and (max-width: 500px) {
        .tableHeader, .tableRow {
            grid-template-columns: 1fr 23px 66px 23px;

            & > :nth-child(3),
            & > :nth-child(4),
            & > :nth-child(5),
            & > :nth-child(7),
            & > :nth-child(9),
            .nextMatch {
                display: none;
            }

            & > :nth-child(6) {
                display: initial;
            }
        }
    }

    @media screen and (max-width: 371px) {
        .tableHeader, .tableRow {
            grid-column-gap: 8px;
        }
    }
}

.tableRowMatches {
    @media screen and (max-width: 371px) {
        column-gap: 8px;
    }
}

/* Sidebar styles */

.contentWrapper {
    &.isWidthBelow500 {
        grid-template-columns: 1fr;
        grid-gap: 16px;

        .tableHeader {
            min-height: unset;
            padding: 0;
            margin-bottom: 8px;
        }
        .tableRow {
            padding: 10px 12px;
            min-height: unset;
        }


        .tableHeader, .tableRow {
            grid-template-columns: 1fr 23px 66px 23px;
            grid-column-gap: 8px;

            & > :nth-child(3),
            & > :nth-child(4),
            & > :nth-child(5),
            & > :nth-child(7),
            & > :nth-child(9),
            .nextMatch {
                display: none;
            }

            & > :nth-child(6) {
                display: initial;
            }
        }


        // Background color

        .table {
            gap: 0;
        }
        .table > :nth-child(odd) {
            background-color: unset;
        }
        .table > :nth-child(even) {
            background-color: $gray-color;
            border-radius: 6px;
        }

        // Other table parts

        .tableTitle {
            font-size: 13px;
            line-height: 16px;
        }
    }
}